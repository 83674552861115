<template>
  <CButton bg="buttonNavbar" color="white" :_hover="{ bg: 'hoverButtonNavbar' }">{{ label }}</CButton>
</template>

<script>
import { CButton } from "@chakra-ui/vue"

export default {
  name: "ButtonOrange",
  components: {
    CButton
  },
  props: [ "label" ]
}

</script>
import { render, staticRenderFns } from "./HeaderDashboard.vue?vue&type=template&id=701128a0&"
import script from "./HeaderDashboard.vue?vue&type=script&lang=js&"
export * from "./HeaderDashboard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CFlex: require('@chakra-ui/vue').CFlex, CAvatar: require('@chakra-ui/vue').CAvatar, CMenuButton: require('@chakra-ui/vue').CMenuButton, CMenuItem: require('@chakra-ui/vue').CMenuItem, CMenuList: require('@chakra-ui/vue').CMenuList, CMenu: require('@chakra-ui/vue').CMenu, CStack: require('@chakra-ui/vue').CStack, CBox: require('@chakra-ui/vue').CBox})
